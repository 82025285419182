<template>
  <b-card
    v-if="dataValue"
    no-body
  >
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>Inscripciones</b-card-title>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ dataValue.total }}
          </h1>
          <small>Total</small>
        </b-col>

        <!-- chart -->
        <b-col
          sm="10"
          class="d-flex justify-content-center mt-3"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="dataValue.activasXCiento"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between mt-1">
        <div class="text-center">
          <b-card-text class="mb-50">
            Activas
          </b-card-text>
            <span class="font-large-1 font-weight-bold">
             <b-link :to="{ path: '/fichas?estado=activas'}">{{ dataValue.activas }}</b-link>
          </span>

        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Próximas Iniciar
          </b-card-text>
            <span class="font-large-1 font-weight-bold">
             <b-link :to="{ path: '/fichas?estado=proximas-iniciar'}">{{ dataValue.totalProximasIniciar }}</b-link>
          </span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Próximas Cerrar
          </b-card-text>
          <span class="font-large-1 font-weight-bold">
             <b-link :to="{ path: '/fichas?estado=proximas-cerrar'}">{{ dataValue.totalProximasCerrar }}</b-link>
          </span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BCardText, BLink,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'ResumenFichas',
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BLink,
  },
  props: ['dataValue'],
  data() {
    return {
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Activas del Total'],
        },
      },
      supportTracker: {
        supportTrackerRadialBar: {
          series: [83],
        },
      },
    }
  },
}
</script>

<style lang="scss">
  .apexcharts-theme-light .apexcharts-tracks g path.apexcharts-radialbar-area{
  stroke: rgba(235, 233, 241, 0.35)!important;
}
</style>
