<template>
  <b-modal
    id="modal-xl"
    centered
    title="Filtrar Inscripción por:"
    no-fade
    ok-title="Aceptar"
    cancel-title="Cerrar"
    cancel-variant="outline-primary"
    @ok="buscar"
  >
    <b-row>
      <b-col md="6">
        <b-form-group>
          <h5 class="font-weight-bold">
            Inscripción
          </h5>
          <b-form-input
            id="ficha"
            v-model="filter.ficha"
            placeholder="N° de Inscripción"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <h5 class="font-weight-bold">
            Orden de Compra
          </h5>
          <b-form-input
            id="ordenCompra"
            v-model="filter.ordenCompra"
            placeholder="N° de Orden de Compra"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'ModalFiltrosConsolidado',
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      filter: {
        ficha: null,
        ordenCompra: null,
      },
    }
  },
  methods: {
    buscar() {
      this.$emit('buscar', this.filter)
      this.filter.ficha = null
      this.filter.ordenCompra = null
    },
  },
}
</script>
<style scoped lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .demo-inline-spacing.curso .custom-radio{
    margin-top: 0.5rem!important;

  }
</style>
