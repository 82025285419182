<template>
  <b-card
    v-if="dataResumen"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Resumen Inscripción</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item @click="mostrarFiltros" href="#">
          Filtrar Inscripción
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <div
        v-for="dataValue in dataResumen"
        :key="dataValue.mode"
        v-bind:class="{'transaction-item':dataValue.visible}"
      >
        <b-media no-body v-if="dataValue.visible">
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="dataValue.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="dataValue.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ dataValue.field }}
            </h6>
            <small>{{ dataValue.text }}</small>
          </b-media-body>
        </b-media>
      </div>
    </b-card-body>
    <modal-filtros ref="modal_filtros" @buscar="buscar"/>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BMediaBody,
} from 'bootstrap-vue'
import ModalFiltrosConsolidado from '@/components/dashboard/ModalFiltrosConsolidado.vue'
import Ripple from 'vue-ripple-directive'
export default {
  name: 'ResumenAlumno',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BMediaBody,
    'modal-filtros': ModalFiltrosConsolidado,
  },
  directives: {
    Ripple,
  },
  props: ['dataResumen'],
  data() {
    return {
      filterData: {
        'num_ficha': null,
        'num_orden_compra': null,
      },
    }
  },
  methods: {
    mostrarFiltros() {
      this.$refs.modal_filtros.$children[0].show()
    },
    buscar(filter) {
      console.log('filter', filter)
      this.filterData.num_ficha = filter.ficha
      this.filterData.num_orden_compra = filter.ordenCompra
      this.$emit('filtrarFicha', this.filterData)
    },
  },

}
</script>

<style scoped>
  .card-transaction .transaction-item:not(:last-child) {
    margin-bottom: 0.5rem;
  }
</style>
